<template>
  <div class="links">
    <b-container fluid="xxl">
      <b-row>
        <b-col>
          <b-form-group
            id="link-group"
            label="URL:"
            label-for="link"
          >
            <b-form-input
              id="link"
              v-model="linkObj.link"
              placeholder="URL"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="comment-group"
            label="Comment:"
            label-for="comment"
          >
            <b-form-input
              id="comment"
              v-model="linkObj.comment"
              placeholder="Comment"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import HelpStore from '@/store/helpstore.js'

export default {
  name: "LinkAutocomplete",
  props: ["exclude", "link"],
  data() {
    return {
      linkObj: this.link ? this.link : {
        id: null,
        comment: '',
        link: ''
      },
      index: this.linkObj ? this.linkObj._index : null,
    };
  },
  updated(){
    HelpStore.item = this.linkObj
  }
};
</script>
