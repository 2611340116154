var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"clearfix"},[_c('b-table',{attrs:{"striped":"","small":"","hover":"","items":_vm.editonsData,"per-page":_vm.perPage,"current-page":_vm.currentPage},scopedSlots:_vm._u([{key:"cell(sigleLegam)",fn:function(data){return [_c('router-link',{attrs:{"to":{name: 'EditionDetails', params: {
          slug: data.item.action.sigleLegamNormalized,
          id: data.item.action.id
        }}},domProps:{"innerHTML":_vm._s(data.item.sigleLegam)}})]}},{key:"cell(bibDescription)",fn:function(data){return [_c('span',{domProps:{"innerHTML":_vm._s(data.item.bibDescription)}})]}},{key:"cell(modernPaging)",fn:function(data){return [_c('span',{domProps:{"innerHTML":_vm._s(data.item.modernPaging)}})]}},{key:"cell(commentGeneral)",fn:function(data){return [_c('span',{domProps:{"innerHTML":_vm._s(data.item.commentGeneral)}})]}},{key:"cell(id)",fn:function(data){return [_c('tdColUUID',{attrs:{"value":data.item.id}})]}},{key:"cell(action)",fn:function(data){return [_c('div',{staticClass:"text-right"},[_c('router-link',{attrs:{"to":{ name: 'EditionDetails', params: {
          slug: data.item.action.sigleLegamNormalized,
          id: data.item.action.id
        }}}},[_c('b-icon',{attrs:{"icon":"link45deg"}})],1),(_vm.editMode && (_vm.showEditionText || _vm.showEditionManuscript || _vm.showLinkEdition))?_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.editRow($event, data.item.action.edition)}}},[_c('b-icon',{attrs:{"icon":"pencil"}})],1):_vm._e(),(_vm.editMode)?_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.deleteRow($event, data.item.action.id)}}},[_c('b-icon',{attrs:{"icon":"trash"}})],1):_vm._e()],1)]}}])}),(_vm.showTotalLable)?_c('b-col',{staticClass:"float-left",attrs:{"sm":"4","md":"3"}},[_vm._v(" Total "+_vm._s(_vm.totalRows)+" "+_vm._s(_vm.totalRows === 1 ? 'entry' : "entries")+" in table ")]):_vm._e(),(_vm.totalRows > 10)?_c('b-col',{staticClass:"my-1 float-right",attrs:{"sm":"3","md":"2"}},[_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"fill","size":"sm","first-number":"","last-number":""},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }