<template>
  <div class="clearfix">
    <b-table striped small hover :items="editonsData" :per-page="perPage" :current-page="currentPage">
      <template #cell(sigleLegam)="data">
        <router-link
          :to="{name: 'EditionDetails', params: {
            slug: data.item.action.sigleLegamNormalized,
            id: data.item.action.id
          }}"
          v-html="data.item.sigleLegam"
        >
        </router-link>
      </template>
      <template #cell(bibDescription)="data">
        <span v-html="data.item.bibDescription" />
      </template>
      <template #cell(modernPaging)="data">
        <span v-html="data.item.modernPaging" />
      </template>
      <template #cell(commentGeneral)="data">
        <span v-html="data.item.commentGeneral" />
      </template>
      <template #cell(id)="data">
        <tdColUUID :value="data.item.id" />
      </template>
      <template #cell(action)="data">
        <div class="text-right">
          <router-link :to="{ name: 'EditionDetails', params: {
            slug: data.item.action.sigleLegamNormalized,
            id: data.item.action.id
          }}">
            <b-icon icon="link45deg"></b-icon>
          </router-link>
          <a
            href="#"
            v-if="editMode && (showEditionText || showEditionManuscript || showLinkEdition)"
            @click="editRow($event, data.item.action.edition)"
            ><b-icon icon="pencil"></b-icon
          ></a>
          <a href="#" v-if="editMode" @click="deleteRow($event, data.item.action.id)"><b-icon icon="trash"></b-icon></a>
        </div>
      </template>
    </b-table>
    <b-col sm="4" md="3" class="float-left" v-if="showTotalLable">
      Total {{ totalRows }} {{ totalRows === 1 ? 'entry' : "entries" }} in table
    </b-col>
    <b-col sm="3" md="2" class="my-1 float-right" v-if="totalRows > 10">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="my-0"
        first-number
        last-number
      ></b-pagination>
    </b-col>
  </div>
</template>

<style scoped>
  * >>> th {
    font-size: 10px !important;
    text-transform: uppercase;
    color: #bbbbbb;
  }
</style>

<script>
  import tdColUUID from '@/components/tdColUUID.vue'

  export default {
    name: 'EditionTable',
    props: ['editions', 'editMode', 'showTotal', 'showEditionText', 'showEditionManuscript', 'showLinkEdition'],
    data(){
      return {
        currentPage: 1,
        totalRows: this.editions.length,
        perPage: 20,
        showTotalLable: this.showTotal ? this.showTotal : false
      }
    },
    components: {
      tdColUUID
    },
    methods: {
      deleteRow(event, editionId){
        event.preventDefault()
        this.$store.dispatch('addNotification', {
          type: 'warning',
          text: `Edition deleted`
        })
        this.$emit('delete', editionId)
      },
      editRow(event, edition) {
        event.preventDefault();
        this.$emit("edit", edition);
      },
    },
    computed: {
      editonsData(){
        return this.editions.map((edition, index) => {
          edition['_index'] = index;
          return {
            sigleLegam: edition.sigleLegam,
            id: edition.id,
            // sigleLegamNormalized: edition.sigleLegamNormalized,
            bibDescription: edition.bibDescription,
            ...(this.showEditionText && {modernPaging: edition.modernPaging}),
            ...(this.showLinkEdition && {comment: edition.comment}),
            commentGeneral: edition.commentGeneral,
            ...(this.showEditionManuscript && { ordering: edition.ordering }),
            ...(this.showEditionManuscript && {
              sigleComment: edition.sigleComment,
            }),
            ...(this.showEditionManuscript && {
              isBaseManuscript: edition.isBaseManuscript,
            }),
            action: {
              sigleLegamNormalized: edition.sigleLegamNormalized,
              id: edition.id,
              edition: edition
            }
          }
        })
      }
    }
  }
</script>
