<template>
  <div class="clearfix">
    <b-table striped small hover :items="studiesData" :per-page="perPage" :current-page="currentPage">
      <template #cell(sigleLegam)="data">
        <router-link
          :to="{ name: 'StudyDetails', params: {
            slug: data.item.action.sigleLegamNormalized,
            id: data.item.action.id
          }}"
          v-html="data.item.sigleLegam"
        >
        </router-link>
      </template>
      <template #cell(commentGeneral)="data">
        <span v-html="data.item.commentGeneral" />
      </template>
      <template #cell(id)="data">
        <tdColUUID :value="data.item.id" />
      </template>
      <template #cell(bibDescription)="data">
        <span v-html="data.item.bibDescription" />
      </template>
      <template #cell(action)="data">
        <div class="text-right">
          <router-link :to="{ name: 'StudyDetails', params: {slug: data.item.action.sigleLegamNormalized, id: data.item.action.id}}">
            <b-icon icon="link45deg"></b-icon>
          </router-link>
          <a
            href="#"
            v-if="editMode && showLinkStudy"
            @click="editRow($event, data.item.action.study)"
            ><b-icon icon="pencil"></b-icon
          ></a>
          <a href="#" v-if="editMode" @click="deleteRow($event, data.item.action.id)"><b-icon icon="trash"></b-icon></a>
        </div>
      </template>
    </b-table>
    <b-col sm="4" md="3" class="float-left" v-if="showTotalLable">
      Total {{ totalRows }} {{ totalRows === 1 ? 'entry' : "entries" }} in table
    </b-col>
    <b-col sm="3" md="2" class="my-1 float-right" v-if="totalRows > 10">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="my-0"
        first-number
        last-number
      ></b-pagination>
    </b-col>
  </div>
</template>

<style scoped>
  * >>> th {
    font-size: 10px !important;
    text-transform: uppercase;
    color: #bbbbbb;
  }
</style>

<script>
  import tdColUUID from '@/components/tdColUUID.vue'

  export default {
    name: 'StudyTable',
    props: ['studies', 'editMode', 'showTotal', 'showLinkStudy'],
    data(){
      return {
        currentPage: 1,
        totalRows: this.studies.length,
        perPage: 20,
        showTotalLable: this.showTotal ? this.showTotal : false
      }
    },
    components: {
      tdColUUID
    },
    computed: {
      studiesData(){
        return this.studies.map((study, index) => {
          study['_index'] = index;
          return {
            sigleLegam: study.sigleLegam,
            id: study.id,
            // sigleOther: study.sigleOther,
            ...(this.showLinkStudy && { comment: study.comment }),
            bibDescription: study.bibDescription,
            commentGeneral: study.commentGeneral,
            action: {
              sigleLegamNormalized: study.sigleLegamNormalized,
              study: study,
              id: study.id
            }
          }
        })
      }
    },
    methods: {
      deleteRow(event, studyId){
        event.preventDefault()
        this.$store.dispatch('addNotification', {
          type: 'warning',
          text: `Study deleted`
        })
        this.$emit('delete', studyId)
      },
      editRow(event, study) {
        event.preventDefault();
        this.$emit("edit", study);
      },
    }
  }
</script>
