<template>
  <div>
    <b-table striped small hover :items="linksData">
      <template #cell(id)="data">
        <tdColUUID :value="data.item.id" />
      </template>
      <template #cell(url)="data">
        <a :href="data.item.url" target="_blank">{{ data.item.url }}</a>
      </template>
      <template #cell(action)="data">
        <div class="text-right">
          <router-link :to="{ name: 'LinkDetails', params: {
            id: data.item.action.id
          }}">
            <b-icon icon="link45deg"></b-icon>
          </router-link>
          <a
            href="#"
            v-if="editMode"
            @click="editRow($event, data.item.action.link)"
            ><b-icon icon="pencil"></b-icon
          ></a>
          <a href="#" v-if="editMode" @click="deleteRow($event, data.item.action.id)"><b-icon icon="trash"></b-icon></a>
        </div>
      </template>
    </b-table>
    <b-col sm="4" md="3" v-if="showTotalLable">
      Total {{ linksData.length }} {{ linksData.length === 1 ? 'entry' : "entries" }} in table
    </b-col>
  </div>
</template>

<style scoped>
  * >>> th {
    font-size: 10px !important;
    text-transform: uppercase;
    color: #bbbbbb;
  }
  * >>> td {
    word-break: break-all;
  }
</style>

<script>
  import tdColUUID from '@/components/tdColUUID.vue'

  export default {
    name: 'LinkTable',
    props: ['links', 'editMode', 'showTotal'],
    data(){
      return {
        showTotalLable: this.showTotal ? this.showTotal : false
      }
    },
    computed: {
      linksData(){
        return this.links.map((link, index) => {
          link['_index'] = index;
          return {
            id: link.id,
            url: link.link,
            comment: link.comment,
            action: {
              link: link,
              id: link.id
            }
          }
        })
      }
    },
    components: {
      tdColUUID
    },
    methods: {
      deleteRow(event, linkId){
        event.preventDefault()
        this.$emit('delete', linkId)
      },
      editRow(event, link) {
        event.preventDefault();
        this.$emit("edit", link);
      },
    }
  }
</script>
