<template>
  <div class="reviewDetails">
    <div v-if="loading || !review">
      <b-container fluid="xxl">
        <b-row class="justify-content-md-center">
          <b-col class="mt-5">
            <div class="spinner-border m-5" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else>
      <b-container fluid="xxl">
        <b-row>
          <b-col class="pl-0">
            <PageTitle
              v-bind:title="review.bibDescription"
              :pretitle="'Review details'"
            />
          </b-col>
          <b-col>
            <div class="mt-5 text-right">
              <span v-if="editMode && config.editable">
                <button @click="saveEdit" class="btn-legam-xs mb-2 btn-primary">
                  <b-icon icon="lock-fill"></b-icon>
                  Save changes
                </button>
                <button @click="cancelEdit" class="btn-legam-xs mb-2">
                  Cancel
                </button>
                <button
                  v-if="$store.getters.checkRole('legam.bib.review.delete')"
                  @click="deleteItem"
                  class="btn-legam-xs mb-2 btn-danger"
                >
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
              <span v-else>
                <button
                  @click="editMode = true"
                  v-if="$store.getters.checkRole('legam.bib.review.modify')"
                  class="btn-legam-xs mb-2"
                >
                  <b-icon icon="unlock-fill"></b-icon>
                  Unlock editing
                </button>
                <button
                  v-if="
                    !$store.getters.checkRole('legam.bib.review.modify') &&
                    $store.getters.checkRole('legam.bib.review.delete')
                  "
                  @click="deleteItem"
                  class="btn-legam-xs mb-2 btn-danger"
                >
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
            </div>
          </b-col>
        </b-row>

        <b-row class="justify-content-md-center">
          <b-col>
            <article>
              <b-row>
                <b-col>
                  <h6>Bib Description</h6>
                  <b-form-input
                    id="bibDescription"
                    v-model="review.bibDescription"
                    placeholder="Title"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="review.bibDescription"></span>
                </b-col>
                <b-col>
                  <h6>Comment General</h6>
                  <b-form-input
                    id="commentGeneral"
                    v-model="review.commentGeneral"
                    placeholder="First name"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="review.commentGeneral"></span>
                </b-col>
              </b-row>
              <!-- <b-row class="mt-3">
                <b-col>
                  <h6>Scholars</h6>
                  <AuthorSelect
                    :selected="review.authors"
                    @select="addAuthors"
                    v-if="editMode"
                  />
                  <span v-else v-html="review.authors.map(author => author.name).join('; ')"></span>
                </b-col>
              </b-row> -->

              <hr>

              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-1 class="text-left">
                      Editions <small>({{ review.editions.length }} {{ review.editions.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-1" accordion="my-accordion-1" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-edition>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-edition" title="Add edition" ok-only ok-variant="secondary" size="lg">
                        <EditionAutocomplete @select="addEdition" />
                      </b-modal>
                      <EditionTable
                        :showTotal="false"
                        :editions="review.editions"
                        @delete="deleteEdition"
                        :editMode="editMode"
                        v-if="review.editions.length"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-2 class="text-left">
                      Scholars <small>({{ review.authors.length }} {{ review.authors.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-2" accordion="my-accordion-2" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-author>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-author" title="Add scholar" ok-only ok-variant="secondary" size="lg">
                        <AuthorAutocomplete @select="addAuthor" />
                      </b-modal>
                      <AuthorTable
                        :showTotal="false"
                        :authors="review.authors"
                        @delete="deleteAuthor"
                        :editMode="editMode"
                        v-if="review.authors.length"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-3 class="text-left">
                      Studies <small>({{ review.studies.length }} {{ review.studies.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-3" accordion="my-accordion-3" role="tabpanel">
                    <b-card-body>
                      <b-card-text>
                        <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-study>
                          <b-icon icon="plus"></b-icon>
                          Add
                        </span>
                        <b-modal id="modal-add-study" title="Add study" ok-only ok-variant="secondary" size="lg">
                          <StudyAutocomplete @select="addStudy" />
                        </b-modal>
                        <StudyTable
                          :showTotal="false"
                          :studies="review.studies"
                          @delete="deleteStudy"
                          :editMode="editMode"
                          v-if="review.studies.length"
                        />
                        <span v-else>No data</span>
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-4 class="text-left">
                      Links <small>({{ review.links.length }} {{ review.links.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-4" accordion="my-accordion-4" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-link>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-link" title="Add link" ok-only ok-variant="secondary" @ok="addLink" size="lg">
                        <LinkAutocomplete :link="modalLink" />
                      </b-modal>
                      <LinkTable
                        :showTotal="false"
                        :links="review.links"
                        @delete="deleteLink"
                        @edit="editLink"
                        :editMode="editMode"
                        v-if="review.links.length"
                        :key="indexLink"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </article>
          </b-col>
        </b-row>
        <div class="mt-4" v-if="config.editable">
          <h6>Logs</h6>
          <ChangeLogsTable
            :objectType="'review'"
            :objectId="id"
            :key="'logs' + index"
          />
        </div>
      </b-container>
    </div>
  </div>
</template>

<style scoped>
* >>> th {
  font-size: 10px !important;
  text-transform: uppercase;
  color: #bbbbbb;
}
</style>

<script>
import { mapState } from "vuex";
import router from "@/router";
import PageTitle from "@/components/PageTitle.vue";
import ChangeLogsTable from "@/components/ChangeLogsTable.vue";
import AuthorTable from '@/components/Bibliography/AuthorTable.vue'
import AuthorAutocomplete from '@/components/Bibliography/AuthorAutocomplete.vue'
import EditionAutocomplete from '@/components/Bibliography/EditionAutocomplete.vue'
import EditionTable from '@/components/Bibliography/EditionTable.vue'
import StudyAutocomplete from '@/components/Bibliography/StudyAutocomplete.vue'
import StudyTable from '@/components/Bibliography/StudyTable.vue'
import LinkTable from '@/components/Bibliography/LinkTable.vue'
import LinkAutocomplete from '@/components/Bibliography/LinkAutocomplete.vue'
import HelpStore from '@/store/helpstore.js'
import config from '@/config.js'

export default {
  name: "ReviewDetails",
  data() {
    return {
      id: this.$route.params.id,
      loading: true,
      editMode: false,
      index: 0,
      indexLink: 0,
      modalLink: null,
      config: config,
    };
  },
  components: {
    PageTitle,
    ChangeLogsTable,
    AuthorTable,
    AuthorAutocomplete,
    EditionAutocomplete,
    EditionTable,
    StudyAutocomplete,
    StudyTable,
    LinkTable,
    LinkAutocomplete,
  },
  computed: {
    ...mapState({
      review: (state) => state.review.review,
    }),
  },
  mounted() {
    this.$store.dispatch("loadReview", this.id);
  },
  watch: {
    id() {
      this.loading = true;
      this.$store.dispatch("loadReview", this.id);
    },
    review() {
      this.loading = false;
    },
  },
  methods: {
    cancelEdit() {
      this.editMode = false;
      this.$store.dispatch("loadReview", this.id);
      this.$store.dispatch("addNotification", {
        type: "warning",
        text: `Changes dismissed`,
      });
    },
    saveEdit() {
      this.editMode = false;
      this.$store.dispatch("saveReview", this.review).then(() => {
        this.$store.dispatch("addNotification", {
          type: "success",
          text: `Changes saved`,
        });
        this.index++;
      });
    },
    deleteItem() {
      this.$bvModal.msgBoxConfirm("Are you sure?").then((value) => {
        if (value) {
          this.$store.dispatch("deleteReview", this.review).then(() => {
            router.push("/reviews");
          });
        }
      });
    },

    addAuthor(author){
      this.$bvModal.hide('modal-add-author')
      this.$store.dispatch('addNotification', {
        type: 'success',
        text: `Author added`
      })
      this.review.authors.push(author)
    },
    deleteAuthor(authorId){
      this.review.authors = this.review.authors.filter(author => author.id != authorId)
    },

    addEdition(edition){
      this.$bvModal.hide('modal-add-edition')
      this.$store.dispatch('addNotification', {
        type: 'success',
        text: `Edition added`
      })
      this.review.editions.push(edition)
    },
    deleteEdition(editionId){
      this.review.editions = this.review.editions.filter(
        edition => edition.id != editionId
      )
    },

    addLink(){
      this.modalLink = null;
      if (HelpStore.item){
        if (HelpStore.item._index != null){
          this.review.links[HelpStore.item._index] = HelpStore.item;
        }
        else {
          this.review.links.push(HelpStore.item);
        }
        this.indexLink++;
      }
    },
    deleteLink(linkId){
      this.review.links = this.review.links.filter(link => link.id != linkId)
    },
    editLink(link){
      this.modalLink = link;
      this.$bvModal.show('modal-add-link');
    },

    addStudy(study){
        this.$bvModal.hide('modal-add-study')
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Study added`
        })
        this.review.studies.push(study)
      },
      deleteStudy(studyId){
        this.review.studies = this.review.studies.filter(study => study.id != studyId)
      },
  },
};
</script>
